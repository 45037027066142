// @flow
import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';
import Contacts from '../../Sidebar/Contacts/Contacts';
const Author = () => {
  const { author } = useSiteMetadata();
  return (
    <div className={styles['author']}>
      <p className={styles['author__bio']}>
        {author.bio}
        <p>تابعونا على مختلف شبكات التواصل الإجتماعي.</p> 
      </p>
      <Contacts contacts={author.contacts} />
    </div>
  );
};

export default Author;
