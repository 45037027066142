// @flow
import React from 'react';
import moment from 'moment';
import 'moment/locale/ar-dz'  // without this line it didn't work
import styles from './Meta.module.scss';

type Props = {
  date: string
};


const Meta = ({ date }: Props) => (
  <div className={styles['meta']}>
    <p className={styles['meta__date']}> نشر في {moment(date).format('D MMM YYYY')}</p>
  </div>
);


export default Meta;
